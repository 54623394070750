import { Component, Vue, Watch } from 'vue-property-decorator';
import { OsTable } from '@/components';
import { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { CustomerResource, DepartmentResource } from '@/resource/model';
import { OsQueryItemOption } from '@/components/os-table-query/os-table-query';
import { Paging } from '@/api/base';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { customerService, departmentService } from '@/api';
import { debounce, messageError, translation } from '@/utils';
import { Message } from 'element-ui';
import { dateFormat } from '@/filters';

@Component({
  components: {}
})
export default class CustomerAllocation extends Vue {
  public tableOption: OsTableOption<CustomerResource> = {
    loading: false,
    data: [],
    fit: true
  };

  /**
   * 表格列配置
   */
  public columnOption: Array<OsTableColumn<CustomerResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true
    },
    {
      prop: 'customerName',
      label: 'customer.customerName',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createUserName',
      label: 'customer.allocatePersonnel',
      minWidth: '150px',
      showOverflowTooltip: true
    },
    {
      prop: 'createTime',
      label: 'customer.allocateTime',
      minWidth: '150px',
      showOverflowTooltip: true,
      formatter: (row: object): string => {
        return dateFormat((row as CustomerResource).createTime);
      }
    }
  ];

  /**
   * table上方的条件查询配置
   */
  public queryItemsOption: Array<OsQueryItemOption> = [
    {
      type: 'Input',
      field: 'customerName',
      label: 'customer.customerName',
      option: {
        placeholder: 'customer.customerName'
      }
    }
  ];

  /**
   * table上方的表格操作配置
   */
  public operationOptions: Array<OperationOption> = [
    {
      type: 'danger',
      plain: true,
      slot: 'start',
      label: 'customer.cancelAssignment',
      disabled: true,
      operationType: 'delete',
      permissionCode: 'customerAllocation:cancelAssignment',
      handleClick: (): void => {
        console.log('取消分配');
        this.cancelAssignment();
      }
    }
  ];
  public totalData = 0;
  public selectedRows: Array<CustomerResource> = [];

  public deptList: Array<any> = [];

  public defaultProps: { children: string; label: string } = {
    children: 'children',
    label: 'depName'
  };

  private queryForm: Partial<{
    customerName: string;
    depId: number | null;
  }> = {
    customerName: '',
    depId: 1
  };

  private paging: Paging = {
    currentPage: 1,
    showCount: 10
  };

  public created(): void {
    this.loadData();
    this.getDeptList();
  }

  public queryClick(): void {
    this.reloadData();
  }

  public reloadData(): void {
    this.paging.currentPage = 1;
    (this.$refs.customerAllocationTable as OsTable).clearSelection();
    this.selectedRows = [];
    this.loadData();
  }

  public pagingData(): void {
    this.loadData();
  }

  public handleSelectionChange(selectedData: Array<CustomerResource>): void {
    this.selectedRows = selectedData;
  }

  public handleNodeClick(nodeData: any): void {
    this.queryForm.depId = nodeData.id;
    this.reloadData();
  }

  public cancelAssignment(): void {
    const ids = this.selectedRows.map(x => x.id!);
    customerService
      .cancelAssignment(ids)
      .then(() => {
        Message.success(translation('operationRes.operationSuccess'));
        this.loadData();
      })
      .catch(error => {
        messageError(error);
      });
  }
  @debounce()
  private loadData(): void {
    this.tableOption.loading = true;
    customerService
      .getCustomerList(this.queryForm as CustomerResource, this.paging)
      .then(res => {
        this.tableOption.data = res.data;
        this.totalData = res.total;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  private getDeptList(): void {
    departmentService
      .getList(this.queryForm as DepartmentResource)
      .then(res => {
        this.deptList = res;
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        this.tableOption.loading = false;
      });
  }

  @Watch('selectedRows')
  private handleSelectedChanged(value: Array<any>): void {
    this.operationOptions.forEach(x => {
      if (x.operationType === 'delete') {
        x.disabled = value.length === 0;
      }
    });
  }
}
