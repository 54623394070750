import { render, staticRenderFns } from "./customer-allocation.vue?vue&type=template&id=478f790a&scoped=true&"
import script from "./customer-allocation.ts?vue&type=script&lang=ts&"
export * from "./customer-allocation.ts?vue&type=script&lang=ts&"
import style0 from "./customer-allocation.scoped.scss?vue&type=style&index=0&id=478f790a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "478f790a",
  null
  
)

export default component.exports